import React, { Fragment, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import Skeleton from 'react-loading-skeleton';

import HeaderBar from 'components/header-bar';
import CouponCard from 'components/coupon-card';
import ModalDetail from './ModalDetail';
import ModalUsage from './ModalUsage';

import { useAPI } from 'utils/api';
import { COUPON_STATUS } from 'utils/constants/couponStatus.js';
import { callAppFunc } from 'utils/jsbridge';

import IconEmpty from 'images/icons/empty.svg';

const UsageBtn = styled.button`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  width: 88px;
  height: 30px;
  padding: 0;
`;

const PageContent = styled.div`
  padding: 70px 16px 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 10px;
  padding: 12px 0;
`;

const Btn = styled.div`
  border-radius: 30px;
  border: 1px solid #5fd2da;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #868686;
  width: 94px;
  height: 37px;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #eafeff;
      font-weight: 700;
      color: #3b3516;
    `}
`;

const CouponListWrapper = styled.div`
  overflow-y: scroll;
  padding: 2px;
  flex: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CouponList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  width: 100%;
`;

const Empty = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 43px;
`;

const EmptyTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-top: 15px;
`;

const EmptySubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  margin-top: 8px;
`;

const GoBtn = styled(Btn)`
  width: 187px;
  height: 40px;
  background-color: #5fd2da;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 24px;
`;

const btnList = [
  {
    key: COUPON_STATUS.CAN_USE,
    label: '可使用',
    emptyTitle: '沒有可用的折價券',
  },
  {
    key: COUPON_STATUS.CAN_NOT_USE,
    label: '尚未啟用',
    emptyTitle: '沒有尚未啟用的折價券',
  },
  {
    key: COUPON_STATUS.NOT_ACTIVE,
    label: '不可使用',
    emptyTitle: '沒有不可使用的折價券',
  },
];

const Coupon = () => {
  const api = useAPI();

  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalUsage, setShowModalUsage] = useState(false);
  const [status, setStatus] = useState(COUPON_STATUS.CAN_USE);
  const [couponListCanUse, setCouponListCanUse] = useState([]);
  const [couponListCanNotUse, setCouponListCanNotUse] = useState([]);
  const [couponListNotActive, setCouponListNotActive] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [modalDetailText, setModalDetailText] = useState('');
  const [usageList, setUsageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageInit, setIsPageInit] = useState(false);

  const closeModalDetail = () => {
    setShowModalDetail(false);
  };

  const closeModalUsage = () => {
    setShowModalUsage(false);
  };

  const getUsage = () => {
    api
      .getUsageList()
      .then((res) => {
        console.log(res);
        if (res) {
          setUsageList(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMyCouponList = (status) => {
    setCouponList([]);
    setIsLoading(true);
    api
      .getMyCouponList(status)
      .then((res) => {
        if (res) {
          setIsPageInit(true);
          setIsLoading(false);
          setCouponList(res);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getMyCouponList(COUPON_STATUS.CAN_USE);
    getUsage();
  }, []);

  useEffect(() => {
    if (isPageInit) {
      setIsPageInit(false);
    }
  }, [isPageInit]);

  return (
    <Fragment>
      <HeaderBar
        title="我的折價券"
        fixed
        extra={
          <UsageBtn onClick={() => setShowModalUsage(true)}>使用辦法</UsageBtn>
        }
        goBack={() => callAppFunc('backHome', {})}
      />
      <PageContent>
        <BtnGroup>
          {btnList.map((btn) => {
            return (
              <Btn
                key={'status-' + btn.key}
                isActive={status === btn.key}
                onClick={() => {
                  setStatus(btn.key);
                  getMyCouponList(btn.key);
                }}
              >
                {btn.label}
              </Btn>
            );
          })}
        </BtnGroup>

        {isLoading ? (
          <Skeleton style={{ marginBottom: 10 }} count={5} height={126} />
        ) : couponList.length > 0 ? (
          <CouponListWrapper>
            <CouponList>
              {couponList?.map((coupon, idx) => {
                return (
                  <CouponCard
                    key={'coupon-' + idx}
                    coupon={coupon}
                    isFromMyCoupon={true}
                    showTicketDetail={(text) => {
                      setModalDetailText(text);
                      setShowModalDetail(true);
                    }}
                    status={status}
                  />
                );
              })}
            </CouponList>
          </CouponListWrapper>
        ) : couponList.length === 0 &&
          isLoading !== true &&
          isPageInit !== true ? (
          <Empty>
            <img src={IconEmpty} />
            <EmptyTitle>
              {btnList.find((b) => b.key === status).emptyTitle}
            </EmptyTitle>
            <EmptySubtitle>前往折價券中心了解更多</EmptySubtitle>
            <GoBtn
              onClick={() => {
                navigate('/coupon', {
                  replace: false,
                });
              }}
            >
              前往折價券中心
            </GoBtn>
          </Empty>
        ) : undefined}
      </PageContent>

      <ModalDetail
        text={modalDetailText}
        isOpen={showModalDetail}
        onOk={closeModalDetail}
      />

      <ModalUsage
        usageList={usageList}
        isOpen={showModalUsage}
        onCancel={closeModalUsage}
      />
    </Fragment>
  );
};

export default Coupon;
